import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyCkhBtbo7K78x7yiFTU3xESvSs0zUoC8pU',
  authDomain: 'codexplus-afcf7.firebaseapp.com',
  projectId: 'codexplus-afcf7',
  storageBucket: 'codexplus-afcf7.appspot.com',
  messagingSenderId: '561173061917',
  appId: '1:561173061917:web:46c6102d52c66113fa81f4',
  measurementId: 'G-FV47QE40W7',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
